/* Sidebar.css */
.sidebar {
  position: fixed;
  top: 0;
  left: -100%; /* Initially hidden */
  width: 100%;
  height: 100%;
  background-color: #d42c34;
  color: #fff !important;
  transition: left 0.3s ease;
  z-index: 100000; /* Ensure it is above other elements */
  padding: 50px 20px 20px 50px;
  overflow: hidden;
}

.sidebar.open {
  left: 0; /* Show the sidebar when open */
}

.sidebar button {
  background: none;
  border: none;
  color: #fff !important;
  font-size: 16px;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}
.dropdown-menu {
  background-color: #333; /* dropdown ka background color */
  border: 1px solid #ccc; /* border */
  border-radius: 4px; /* rounded corners */
  z-index: 2; /* z-index ko ensure karna */
}

.dropdown-item {
  color: #fff; /* text color */
  padding: 10px; /* padding */
  text-decoration: none; /* underline ko hataana */
  display: block; /* block display */
}

.dropdown-item:hover {
  background-color: #555; /* hover par background color */
}
.mobiledropdown{
  color: #000 !important;
}