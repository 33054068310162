html{
  overflow-x: hidden;
}
/* Define keyframes for the slide animation */
@keyframes slide {
  0% {
    transform: translateX(100%); /* Start offscreen to the right */
  }
  100% {
    transform: translateX(-1000%); /* Slide fully to the left */
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: red;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    left: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    right: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll 10s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slide {
    height: 100px;
    width: 100%;
  }
}
/* Apply animation to the slideshow container */
#slideshow {
  overflow: hidden; /* Hide overflowing content */
}

/* Apply animation to each col-lg-2 div */
.col2column {
  animation: slide 10s linear infinite; /* Adjust the duration and timing function as needed */
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (min-width: 990px) {
  .Knowmorestyle {
    border-radius: 10px;
    margin-left: 50px;
  }
  .homeheadervideo {
    width: 100vw;
  }
  .Headerrow {
    margin-top: -7%;
    margin-right: 0px;
    padding-right: 0px;
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 102vh;
    /* margin: 0px; */
    padding: 0px;
  }
  .Headerrowabout {
    margin-top: -7%;
    margin-right: 0px;
    padding-right: 0px;
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 70vh;
    /* margin: 0px; */
    padding: 0px;
  }
  .aboutcontent {
    float: left;
    text-align: justify;
    padding-top: 10px;
    /* padding-left: 50px; */
  }
  .numstyle1 {
    float: left;
    font-size: 50px;
    color: #fff;
    font-weight: 800;
    text-align: right;
  }
  .numstyle2 {
    float: left;
    font-size: 30px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
  }
  .featuredbookdiv {
    display: block;
    margin: 0px;
    padding: 0px 0px 20px 0px;
    clear: both;
    background: linear-gradient(to top, rgb(238, 238, 238, 0.4), #fff);
    height: 110vh;
  }
  .homecoursesstyle {
    width: 100%;
    height: 50vh;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .bookhelpstyle {
    width: 70%;
    text-align: justify;
    margin-left: 16%;
    box-shadow: 10px -5px 5px rgba(0, 0, 0, 0.2), 0px 5px 5px rgba(0, 0, 0, 0.2);
    padding: 50px;
    background-color: #fff;
  }
  /* .col2column1 {
    background-image: url(../Images/Logo/1.jpg);
    background-position: center;
    background-size: cover;
    height: 18vh;
    width: 150px;
  } */
}

@media screen and (max-width: 990px) {
  .col2column1 {
    background-position: center;
    background-size: cover;
    height: 18vh;
    width: 150px;
  }
  .Knowmorestyle {
    border-radius: 10px;
    height: 34vh;
  }
  .homeheadervideo {
    height: 100vh;
  }
  .Headerrow {
    margin-top: -25%;
    margin-right: 0px;
    padding-right: 0px;
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 101vh;
    /* margin: 0px; */
    padding: 0px;
  }
  .aboutcontent {
    float: left;
    text-align: justify;
    padding-top: 10px;
    /* padding-left: 50px; */
  }
  .numstyle1 {
    float: left;
    font-size: 50px;
    color: #fff;
    font-weight: 800;
    text-align: center;
  }
  .numstyle2 {
    float: left;
    font-size: 30px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
  .featuredbookdiv {
    display: block;
    margin: 0px;
    padding: 0px 0px 20px 0px;
    clear: both;
    background: linear-gradient(to top, rgb(238, 238, 238, 0.4), #fff);
    /* height: 300vh; */
  }
  .homecoursesstyle {
    width: 100%;
    /* height: 50vh; */
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .bookhelpstyle {
    /* width: 70%; */
    text-align: justify;
    /* margin-left: 16%; */
    box-shadow: 10px -5px 5px rgba(0, 0, 0, 0.2), 0px 5px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    background-color: #fff;
  }
}

.book {
  width: 100%;
  height: 60vh;
  position: relative;
  text-align: center;
  margin: 2.5%;
}

.book-cover {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform-origin: 0 50%;
  -webkit-transform-origin: 0 50%;
  background: #111;
  background-size: cover;
  border-radius: 3px;
  box-shadow: inset 4px 1px 3px #ffffff60, inset 0 -1px 2px #00000080;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.book .book-cover {
  background-size: 100% 100%;
}

.effect {
  width: 20px;
  height: 100%;
  margin-left: 10px;
  border-left: 2px solid #00000010;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.5s ease;
}

.light {
  width: 90%;
  height: 100%;
  position: absolute;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  top: 0;
  right: 0;
  opacity: 0.1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.book:hover {
  cursor: pointer;
}

.book:hover .book-cover {
  transform: perspective(2000px) rotateY(-30deg);
  -webkit-transform: perspective(2000px) rotateY(-30deg);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  box-shadow: inset 4px 1px 3px #ffffff60, inset 0 -1px 2px #00000080,
    10px 0px 10px -5px #00000030;
}

.book:hover .effect {
  width: 40px;
  /** margin-left:13px;
    opacity: 0.5; **/
}

.book:hover .light {
  opacity: 1;
  width: 70%;
}

.book-inside {
  width: calc(100% - 2px);
  height: 96%;
  position: relative;
  top: 2%;
  border: 1px solid grey;
  border-radius: 3px;
  background: white;
  box-shadow: 10px 40px 40px -10px #00000030, inset -2px 0 0 grey,
    inset -3px 0 0 #dbdbdb, inset -4px 0 0 white, inset -5px 0 0 #dbdbdb,
    inset -6px 0 0 white, inset -7px 0 0 #dbdbdb, inset -8px 0 0 white,
    inset -9px 0 0 #dbdbdb;
}

.title {
  font-size: 22px;
  width: 100%;
  color: #333;
  text-align: center;
  position: absolute;
  top: -30px;
  height: 1px;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.title p {
  width: 100%;
  margin: 0;
  line-height: 1.3;
}

.btn {
  position: relative;
  background: #aaa;
  color: #fff;
  font-weight: bold;
  padding: 15px 30px;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 50px;
  bottom: -50px;
  display: inline-block;
  opacity: 0;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.book:hover .btn,
.book:hover .title {
  opacity: 1;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-content {
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0)),
    url("../Images/HomeVideo.mp4");
  background-size: cover;
  background-position: center;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 100;
}
.navbar-white-bg {
  background-color: #ffffff; /* Set background color to white */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add bottom box shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth color and shadow change */
}

.nav-link-black {
  color: #000 !important; /* Important to override any other styles */
}
.search-wrapper {
  position: relative;
}

.search-box {
  position: absolute;
  top: 140%;
  left: -150px;
  width: 20vw; /* Adjust width as needed */
  display: none;
}

.search-box input {
  width: calc(100% - 40px);
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.search-box button {
  width: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  cursor: pointer;
}

.search-boxOpen {
  position: absolute;
  top: 140%;
  left: -150px;
  width: 20vw; /* Adjust width as needed */
  display: block;
}

.search-boxOpen input {
  width: calc(100% - 40px);
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.search-boxOpen button {
  width: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  cursor: pointer;
}
.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #fff;
}

.table th {
  background-color: #f6f2e7;
  color: black;
  font-weight: 600;
}
.form-controlCustomized {
  display: block;
  width: 100%;
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-controlCustomized:focus {
  border-color: #eee;
  outline: 0;
}
.quantity {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quantity button {
  background-color: #ddd;
  color: #212121;
  border: none;
  cursor: pointer;
  font-size: 20px;
  width: 30px;
  height: auto;
  text-align: center;
  transition: background-color 0.2s;
}

.quantity button:hover {
  background-color: #ddd;
}

.input-box {
  width: 40px;
  text-align: center;
  border: none;
  padding: 8px 10px;
  font-size: 16px;
  outline: none;
  background-color: #fff;
}

/* Hide the number input spin buttons */
.input-box::-webkit-inner-spin-button,
.input-box::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-box[type="number"] {
  -moz-appearance: textfield;
}
/* .slick-cloned {
  width: auto !important;
  float: left;
} */

@media screen and (min-width: 990px) {
  .aboutusheader {
    text-align: "center";
    padding-top: 150px;
  }
  .whowearestyle {
    text-align: left;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .whowearestyleparagraph {
    text-align: justify;
    width: 80%;
  }
  .whowearestyleparagraph1 {
    text-align: justify;
    width: 80%;
  }
  .paginationstyle {
    max-width: 100vw;
  }
}

@media screen and (max-width: 990px) {
  .aboutusheader {
    text-align: "center";
    padding-top: 50px;
  }
  .gradientoverlaystyle {
    height: 70vh;
  }
  .whowearestyle {
    text-align: left;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .whowearestyleparagraph {
    text-align: justify;
    width: 100%;
  }
  .paginationstyle {
    max-width: 100vw;
    overflow: scroll;
  }
}
@media screen and (min-width: 990px) {
  .HideInDesktop {
    display: none;
  }
  .bookdetailsstyle {
    padding: 20px 0px 20px 100px;
    margin: 0px;
  }
  .bookdetailsstyle2 {
    width: 18vw;
    height: 45vh;
  }
  .shippingcontent {
    background: linear-gradient(to top, rgb(216, 32, 40, 0.1), #fff);
    padding: 50px 50px 5px 50px;
  }
  .shippingheading {
    background-color: #f6f2e7;
    padding: 20px;
    width: 100%;
    height: 12vh;
  }
}

@media screen and (max-width: 990px) {
  .HideInPhone {
    display: none;
  }
  .bookdetailsstyle {
    margin: 0px;
  }
  .bookdetailsstyle2 {
    height: 45vh;
  }
  .shippingcontent {
    background: linear-gradient(to top, rgb(216, 32, 40, 0.1), #fff);
    padding: 30px 15px 5px;
  }
  .shippingheading {
    background-color: #f6f2e7;
    padding: 20px;
    width: 100%;
    height: 12vh;
  }
  .shippingheadingh3{
    font-size: 22px;
  }
}
