.transaction-container {
  width: 400px;
  margin: 200px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.transaction-header {
  text-align: center;
  margin-bottom: 20px;
}

.transaction-header h2 {
  font-size: 24px;
  color: #333;
}

.btn-back {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-back:hover {
  background-color: #0056b3;
}

.btn-back:focus {
  outline: none; /* Remove button outline on focus */
}

/* Style link as a button */
.transaction-footer a {
  text-decoration: none;
  color: inherit;
}
